import React from 'react';
import Container from 'react-bootstrap/Container';
import AppNavBar from '../../components/navbar/AppNavBar';
import AppFooter from '../../components/footer/AppFooter.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Home.module.css';
import useColors from '../../services/hooks/useColors';
import Typical from 'react-typical';
import { subTagLine, tagLine } from '../../config/strings';
import CartoonButton from '../../components/button/CartoonButton';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const backgroundColor = "purple";
const fontColor = "white"

const steps = [
  'SideProject', 3000,
  'Website', 3000,
  'App', 3000,
  'Newsletter', 3000,
  'Library', 3000
];


function HomeOptionBar(props) {
  return (
      <Navbar className="justify-content-between removeallmarginpadding" expand="lg" collapseOnSelect={true}>
          <Navbar.Toggle aria-controls="responsive-homeoption-nav" />
          <Navbar.Collapse id="responsive-homeoption-nav">
              <Nav className="mr-auto">
                <CartoonButton title="Plan" linkto="/plan" fontClass={styles.homebuttontypography}></CartoonButton>
                <CartoonButton title="Build" linkto="/build" fontClass={styles.homebuttontypography}></CartoonButton>
                <CartoonButton title="Launch" linkto="/launch" fontClass={styles.homebuttontypography}></CartoonButton>
              </Nav>
          </Navbar.Collapse>
      </Navbar>
  )
}

export default function Home() {

  useColors(backgroundColor, fontColor);

  return(
    <>
      <AppNavBar fontClass={styles.navbarfontclass}></AppNavBar>
      <Container>
        <Row className="pv4 mv4">
          <Col></Col>
          <Col xs={10} md={10} lg={10}>
            <div className={styles.hometaglinetypography + " tc"}>{tagLine}</div>
            <div className={styles.hometaglinetypography + " tc"}><Typical wrapper="span" steps={steps} loop={Infinity} /></div>
            <div className={styles.hometaglinetypography + " tc"}>{subTagLine}</div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="pv4 mv4 tc justify-content-center">
          <Col md="auto">
            <HomeOptionBar></HomeOptionBar>
          </Col>
        </Row>
        <Row className="pv4 mv4 tc justify-content-center">
          <Col md="auto">
            <CartoonButton title="subscribe" linkto="https://tinyletter.com/ranuzz" fontClass={styles.homebuttontypography}></CartoonButton>
          </Col>
        </Row>
      </Container>
      <AppFooter></AppFooter>
      
    </>
  );
}