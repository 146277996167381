import React from 'react';
import styles from './button.module.css';


export function StripeButtonAction(props) {
    return (
        <>
              <div className={styles.stripebutton + " " + styles.stripebuttonactionfont} onClick={() => props.linkAction()} href="#">{props.title}</div>
        </>
    );
}

export default function StripeButton(props) {
    return (
        <>
              <a className={styles.stripebutton + " " +props.fontClass} href={props.to}>{props.title}</a>
        </>
    );
}