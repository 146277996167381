import React from 'react';

import styles from './button.module.css';

export default function CartoonButton(props) {
    return (
        <>
            <div className={styles.cartoonbutton + " " + props.fontClass} onClick={() => props.linkAction()}>
                <a href={props.linkto}>{props.title}</a>
            </div>
        </>
    );
}