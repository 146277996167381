import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './views/home/Home.js';
import About from './views/about/About.js';
import Placeholder from './views/placeholder/Placeholder.js';
import TaskActivityConnected from './features/taskactivity/TaskActivityConnected.js';
import Example from './components/card/CarouselExample.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TaskProgress from './features/taskactivity/TaskProgress.js';

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path="/plan">
            <TaskActivityConnected track="Plan"></TaskActivityConnected>
          </Route>
          <Route exact path="/build">
            <TaskActivityConnected track="Build"></TaskActivityConnected>
          </Route>
          <Route exact path="/launch">
            <TaskActivityConnected track="Launch"></TaskActivityConnected>
          </Route>
          <Route exact path="/progress">
            <TaskProgress></TaskProgress>
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/test">
            <Example></Example>
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route>
            <Placeholder title="404" subtitle="Page Not Found" message="............"></Placeholder>
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
