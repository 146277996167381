import { connect } from 'react-redux';
import TaskActivity from "./TaskActivity";
import { changeTrack, nextSlide, pickSlide, prevSlide, randomSlide } from '../../features/taskactivity/TaskActivitySlice';


const mapStateToProps = (state, ownProps) => ({
    track: ownProps.track,
    cards: state.taskactivity.cards,
    goToSlide: state.taskactivity.goToSlide,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    nextSlide: () => dispatch(nextSlide()),
    prevSlide: () => dispatch(prevSlide()),
    randomSlide: () => dispatch(randomSlide()),
    changeTrack: () => dispatch(changeTrack(ownProps.track)),
    pickSlide: (inx) => dispatch(pickSlide(inx))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskActivity);