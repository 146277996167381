import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { appName } from '../../config/strings.js';
import WonkyTitle from '../labels/WonkyTitle.js';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import styles from './AppNavBar.module.css';
import { useDispatch } from 'react-redux';
import { toggleList } from '../../features/taskactivity/TaskActivitySlice.js';

export default function ActivityNavBar(props) {

    const dispatch = useDispatch();

    let classes = styles.navbartypography + " " + styles.activitynavbarfontclass + " " + props.fontClass;

    return (
    <header>
        <Navbar sticky="top" className="justify-content-between" expand="lg">

            <Navbar.Brand href="/" className={classes}>
                <WonkyTitle title={appName}></WonkyTitle>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                
                <Nav className="mr-auto">
                </Nav>

                <Nav className={"mr-sm-5 " + classes} >
                    <Button variant="outline-dark" onClick={() => {dispatch(toggleList());}}>Toggle</Button>&nbsp;
                    <Button variant="outline-dark" onClick={() => {window.location="/progress"}}>Progress</Button>&nbsp;
                    <NavDropdown className={classes} title={props.track} id="nav-dropdown">
                        <NavDropdown.Item href="/plan">Plan</NavDropdown.Item>
                        <NavDropdown.Item href="/build">Build</NavDropdown.Item>
                        <NavDropdown.Item href="/launch">Launch</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                
            </Navbar.Collapse>
        </Navbar>
      </header>
    )
}

//<Button variant="outline-dark" onClick={() => {dispatch(purgeSaved());}}>Reset</Button>&nbsp;