import React from 'react';
import Container from 'react-bootstrap/Container';
import AppNavBar from '../../components/navbar/AppNavBar';
import useColors from '../../services/hooks/useColors';
import AppFooter from '../../components/footer/AppFooter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typical from 'react-typical';
import styles from './Placeholder.module.css';

const backgroundColor = "orange";
const fontColor = "black"

export default function Placeholder(props) {
  
  useColors(backgroundColor, fontColor);
  
  return(
    <>
      <AppNavBar fontClass={styles.placeholderfont}></AppNavBar>
      <Container>
        <Row className="pv4 mv4">
          <Col></Col>
          <Col xs={10} md={10} lg={10}>
            <div className={styles.placeholdertaglinetypography + " tc"}>{props.title}</div>
            <div className={styles.placeholdertaglinetypography + " tc"}><Typical wrapper="span" steps={[props.message, 3000]} loop={Infinity} /></div>
            <div className={styles.placeholdertaglinetypography + " tc"}>{props.subtitle}</div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
      <AppFooter></AppFooter>
    </>
  );
}