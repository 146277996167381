
// TRACK Name
export const PLAN = 'Plan';
export const BUILD = 'Build';
export const LAUNCH = 'Launch';

// TAGS
export const IDEA = 'Idea';

// Cart Type
export const LINK = "link";
export const CHECKBOX = "checkbox";
export const LIST = "list";
export const TEXTBOX = "textbox";
export const OPTION = "option";

// METAKEY
export const LINKS = "links"
export const CHOICES = "choices"