import { PLAN, BUILD, LAUNCH } from '../../config/tracks';
import { LINK, OPTION, CHECKBOX, TEXTBOX } from '../../config/tracks';

const rawCards = [
    ["title", "description", "link", [PLAN, BUILD, LAUNCH], {"meta": "meta"}],
    [
        "Project Summary",
        "Define your product in 500 or less words",
        TEXTBOX,
        [PLAN],
        {}
    ],
    ["One Liner", "Write a one liner for your project", TEXTBOX, [PLAN], {}],
    ["What is it ?", "What are you building, an app, website, newsletter or something else", TEXTBOX, [PLAN], {}],
    [
        "End Goal",
        "What is your end goal?",
        OPTION, [PLAN],
        {
            "choices": [
                "Monetisation",
                "Showcase",
                "Just for fun"
            ]
        }
    ],
    [
        "Domain Name",
        "Find a domain name",
        LINK,
        [PLAN],
        {
            "links": [
                "https://www.shopify.com/tools/domain-name-generator",
                "https://www.godaddy.com/domains/domain-name-generator",
                "https://domainwheel.com/",
                "https://namelix.com/",
            ]
        }
    ],
    ["Hosting", "Choose a hosting provider", OPTION, [BUILD],
        {
            "choices": [
                "AWS",
                "GCP",
                "DigitalOcean"
            ]
        }
    ],
    [
        "Programming Language",
        "Pick a programming language",
        CHECKBOX, [BUILD],
        {
            "choices": [
                "C",
                "Python",
                "Java"
            ]
        }
    ],
    ["Payment", "Do you want to include a payment system ?", OPTION, [BUILD], {
        "choices": [
            "Yes",
            "No",
            "May be Later"
        ]
    }],
    ["Library", "Add a library to use", TEXTBOX, [BUILD], {}],
    ["Cloud Service", "Choose a cloud platform.", OPTION, [BUILD], {
        "choices": [
            "AWS",
            "GCP",
            "DigitalOcean"
        ]
    }],
    ["Design", "Find a tool to create mock ups", LINK, [BUILD], {
        "links": [
            "https://www.figma.com/",
            "https://www.invisionapp.com/",
            "https://www.adobe.com/products/xd.html",
        ]
    }],
    [
        "Submit",
        "Submit your finished product",
        LINK,
        [LAUNCH],
        {
            "links": [
                "https://www.producthunt.com/",
                "https://www.reddit.com/r/SideProject/",

            ]
        }
    ],
    [
        "Blog",
        "Set up a blog for content marketing",
        LINK,
        [LAUNCH],
        {
            "links": [
                "https://medium.com/",
                "https://substack.com/",
                "https://ghost.org/"
            ]
        }
    ],
    [
        "Marketing",
        "Proritze the process spread the word",
        LINK,
        [LAUNCH],
        {
            "links": [
                "https://www.producthunt.com/",
            ]
        }
    ],
    [
        "Get Ideas",
        "Explore projects that help you get inspiration",
        LINK,
        [PLAN],
        {
            "links": [
                "https://www.reddit.com/r/AppIdeas/",
                "http://www.randomideagenerator.com/",
                "https://www.ycombinator.com/library/8g-how-to-get-startup-ideas"
            ]
        }
    ],
    ["Market Survey", "Plan market survey, create questionnaire", LINK, [PLAN], {
        "links": [
            "https://www.typeform.com/",
            "https://www.google.com/forms/about/",
        ]
    }],
    ["Competition Analysis", "Find products similar to one you have planned", LINK, [PLAN], {
        "links": [
            "https://alternativeto.net/",
            "https://www.capterra.com/"
        ]
    }],
    ["Customer Interview", "Get early feedback on idea from potential customers", LINK, [PLAN], {
        "links": [
            "https://www.typeform.com/",
            "https://www.google.com/forms/about/",
        ]
    }],
    ["Product Market Fit", "Understand what product fit is and what it means for your idea.", LINK, [PLAN], {
        "links" : [
            "https://www.youtube.com/watch?v=0LNQxT9LvM0",
            "https://productschool.com/blog/product-management-2/finding-product-market-fit/"
        ]
    }],
    ["Feasibility Analysis", "Can I build X using Y ?", OPTION, [PLAN], {
        "choices": [
            "Yes",
            "No"
        ]
    }],
    ["Monetization", "Are you planning to monetize ? If yes how ?", CHECKBOX, [PLAN], {
        "choices": [
            "Subscription",
            "Displaying Ad",
            "License"
        ]
    }],
    ["Name", "Pick a name for your product", TEXTBOX, [PLAN], {}],
    ["Domain", "Pick a domain name for your product", TEXTBOX, [PLAN], {}],
    ["Logo", "Design a logo for your product.", LINK, [PLAN], {
        "links": [
            "https://romannurik.github.io/AndroidAssetStudio/icons-launcher.html",
            "https://www.logoai.com/logo-maker",
            "https://logomaster.ai/"
        ]
    }],
    ["Landing Page", "Create a landing page for the product or announcement", LINK, [PLAN], {
        "links": [
            "https://startbootstrap.com/",
            "https://www.wix.com/",
            "https://unbounce.com/",
            "https://www.hubspot.com/products/marketing/landing-pages"
        ]
    }],
    ["CTA", "Add a call to action for your product", LINK, [PLAN], {
        "links": [
            "https://tinyletter.com/",

        ]
    }],
    ["Analytics", "Add analytics to your landing page and see traction for Idea", LINK, [PLAN], {
        "links": [
            "https://analytics.google.com",
            "https://mixpanel.com/"
        ]
    }],
    ["MVP Deadline", "Set a deadline for MVP release.", TEXTBOX, [BUILD], {}],
    ["Project Management", "Find a project management tool", LINK, [BUILD], {
        "links": [
            "https://basecamp.com/",
            "https://asana.com/",
            "https://www.atlassian.com/"
        ]
    }],
    ["Frontend Works", "Select a frontend framework and define tasks", LINK, [BUILD], {
        "links": [
            "https://vuejs.org/",
            "https://flutter.dev/",
            "https://reactjs.org/"
        ]
    }],
    ["Backend Works", "Select a backend framework or language and define tasks", LINK, [BUILD], {
        "links": [
            "https://www.djangoproject.com/",
            "https://palletsprojects.com/p/flask/",
            "https://spring.io/projects/spring-boot",
        ]
    }],
    ["Authentication Integration", "Select a service or build your own Auth framework", LINK, [BUILD], {
        "links": [
            "https://auth0.com/",
            "https://firebase.google.com/docs/auth/"
        ]
    }],
    ["Payment Integration", "Integrate with a payment provider.", LINK, [BUILD], {
        "links": [
            "https://stripe.com/",
            "https://www.shopify.com/payments"
        ]
    }],
    ["Landing Page", "Create a landing page for the product", LINK, [BUILD], {
        "links": [
            "https://startbootstrap.com/",
            "https://www.wix.com/",
            "https://unbounce.com/",
            "https://www.hubspot.com/products/marketing/landing-pages"
        ]
    }],
    ["Deployment DevOps", "Create your own or select a managed or serverless option", TEXTBOX, [BUILD], {}],
    ["ELK", "Plan Logging & Reporting", LINK, [BUILD], {
        "links": [
            "https://www.elastic.co/what-is/elk-stack"
        ]
    }],
    ["Testing", "Unit, feature and E2E Testing", TEXTBOX, [BUILD], {}],
    ["Compliance Documents", "Add License, TnC and Priacy Policy documents", LINK, [LAUNCH], {
        "links": [
            "https://tldrlegal.com/",
            "https://www.termsfeed.com/privacy-policy-generator/",
        ]
    }],
    ["Content Marketing", "Write blogs, tweets etc to promote the product.", LINK, [LAUNCH], {
        "links": [
            "https://medium.com/",
            "https://substack.com/",
            "https://ghost.org/"
        ]
    }],
    ["AD Campaign", "Run ad campaign on various platfrom", LINK, [LAUNCH], {
        "links": [
            "https://ads.google.com",
            "https://business.twitter.com/en/advertising.html",
            "https://www.redditinc.com/advertising"
        ]
    }],
    ["Share", "Showcase and Launch your product on various platforms", LINK, [LAUNCH], {
        "links": [
            "https://www.producthunt.com/",
            "https://www.reddit.com/r/SideProject/",
        ]
    }],
    ["Demo", "Create demo for your product", LINK, [LAUNCH], {
        "links": [
            "https://www.loom.com/",
            "https://asciinema.org/"
        ]
    }],
    ["User OnBoarding", "Integrate hands-on onboarding right in your product", LINK, [LAUNCH], {
        "links": [
            "https://userguiding.com/",
            "https://userflow.com/"
        ]
    }],
    ["Analytics", "Add analytics to your landing page and analyse traction", LINK, [LAUNCH], {
        "links": [
            "https://analytics.google.com",
            "https://mixpanel.com/"
        ]
    }],
    ["User Feedback", "Get Feedback from paid and free users", LINK, [LAUNCH], {
        "links": [
            "https://www.intercom.com/",
            "https://www.typeform.com/"
        ]
    }]
];

export const allCards = [];

function createCard(index, title, description, link, tags, meta) {
    return {
        "index": index,
        "title": title,
        "description": description,
        "kind": link,
        "tags": tags,
        "meta": meta
    };
}

for (let i = 1; i < rawCards.length; i++) {
    var card = createCard(
        i,
        rawCards[i][0],
        rawCards[i][1],
        rawCards[i][2],
        rawCards[i][3],
        rawCards[i][4]
    );    
    allCards.push(card);
}

const cardInfo = {
    'Plan' : [],
    'Build': [],
    'Launch': []
};

for (let i = 0; i < allCards.length; i++) {
    
    if (allCards[i].tags.includes(PLAN)) {
        cardInfo[PLAN].push(allCards[i])
    }
    if (allCards[i].tags.includes(BUILD)) {
        cardInfo[BUILD].push(allCards[i])
    }
    if (allCards[i].tags.includes(LAUNCH)) {
        cardInfo[LAUNCH].push(allCards[i])
    }
}

export default cardInfo;