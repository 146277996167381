import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
import Row from 'react-bootstrap/Row';
import debugLog from "../../services/debug/log";
import {ActivityCardSimple} from "./ActivityCard";

export default class ActivityCardGrid extends Component {

    componentDidMount() {
        this.props.changeTrack();
    }

    render() {
        let slides = [];
        for (let i = 0; i < this.props.cards.length; i++) {
            let index = this.props.cards[i].index;
            let saved = localStorage.getItem(index) ?? JSON.stringify(JSON.stringify({}));
            let key = uuidv4();
            slides.push({
                key: key,
                content: 
                
                <ActivityCardSimple key={key} card={JSON.stringify(this.props.cards[i])} saved={JSON.stringify(saved)}>
                </ActivityCardSimple>
            });
        }
        slides = slides.map((slide, index) => {
            return { ...slide, onClick: () => {
                debugLog.info("Loaded slide index %d", index);
                this.props.pickSlide(index)
            }};
        });
        
        return (
            <>
            <br />
            <Row  xs={2} sm={2} md={4} lg={4} className="g-4 justify-content-md-center">
                {slides.map((item) => item.content)}
            </Row>
            </>
        )
    }

}