import { connect } from 'react-redux';
import { changeTrack, nextSlide, pickSlide, prevSlide, randomSlide } from '../../features/taskactivity/TaskActivitySlice';
import ActivityCardGrid from "./ActivityCardGrid"


const mapStateToProps = (state, ownProps) => ({
    track: ownProps.track,
    cards: state.taskactivity.cards,
    saved: state.taskactivity.saved,
    goToSlide: state.taskactivity.goToSlide,
    offsetRadius: state.taskactivity.offsetRadius,
    showNavigation: state.taskactivity.showNavigation,
    config: state.taskactivity.config,
    goToSlideDelay: state.taskactivity.goToSlideDelay
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    nextSlide: () => dispatch(nextSlide()),
    prevSlide: () => dispatch(prevSlide()),
    randomSlide: () => dispatch(randomSlide()),
    changeTrack: () => dispatch(changeTrack(ownProps.track)),
    pickSlide: (inx) => dispatch(pickSlide(inx))
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCardGrid);