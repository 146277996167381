import React from 'react';
import Container from 'react-bootstrap/Container';
import AppNavBar from '../../components/navbar/AppNavBar';
import AppFooter from '../../components/footer/AppFooter.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useColors from '../../services/hooks/useColors';

import styles from './About.module.css';

const backgroundColor = "purple";
const fontColor = "white"

const thisthis = "{this}"

export default function About() {

  useColors(backgroundColor, fontColor);

  return(
    <>
      <AppNavBar fontClass={styles.navbarfontclass}></AppNavBar>
      <Container>
        <Row className={"pv4 mv4 " + styles.aboutpagetypography} >
          <Col></Col>
          <Col xs={10} md={10} lg={10}>
          <h2>About {thisthis}</h2>
            <hr></hr>
            <br></br>

            <h3>What is makeall-launch ?</h3>
            <p>A platform for indie developers to plan, build and launch their software projects</p>
            <br></br>

            <h3>How does the platform help ?</h3>
            <p>It is a toolkit to help developers find the next goal in their product development track and achieve them quickly.</p>
            <br></br>

            <h3>What are product development tracks ?</h3>
            <p>Tracks are phases in the product development process. This platform divides them into three: Plan, Build and Launch.</p>
            <br></br>

            <h3>Why there is no signup ?</h3>
            <p>The platform acts as a stateless guide and doesn't need user information to function. It does maintain an offline state to keep track of a few things.</p>
            <br></br>

            <hr></hr>
            <p>
              To stay updated follow me on
                  <a target="_blank" rel="noreferrer" href="https://github.com/ranuzz"> github </a>
                and
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/ranuzzzz"> twitter </a>
                or
                  <a target="_blank" rel="noreferrer" href="https://tinyletter.com/ranuzz"> signup for the newsletter</a>  .
            </p>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <AppFooter></AppFooter>
    </>
  );
}