import React from 'react';
import useColors from '../../services/hooks/useColors';
import Container from 'react-bootstrap/esm/Container';
import AppFooter from '../../components/footer/AppFooter';

import styles from './TaskActivity.module.css';
import TaskProgressNavBar from '../../components/navbar/TaskProgressNavBar';
import { useSelector } from 'react-redux';
import { selectSaved } from './TaskActivitySlice';
import Row from 'react-bootstrap/Row';
import { allCards } from './CardInfo';
import { ActivityCardSimple } from '../../components/card/ActivityCard';
const backgroundColor = "orange";
const fontColor = "black"

export default function TaskProgress(props) {

    useColors(backgroundColor, fontColor);
    const saved = useSelector(selectSaved);
    
    let savedCards = [];

    allCards.map(card => {
        if (Object.keys(saved[card.index]).length !== 0) {
            savedCards.push({
                card: card,
                saved: saved[card.index]
            })
        } else {
            
        }
    })

    return (
        <>
            <TaskProgressNavBar track="SelectTrack" fontClass={styles.taskactivityappfontwhite}></TaskProgressNavBar>
            <Container>
                <Row  xs={2} sm={2} md={4} lg={4} className="g-4 pv4 justify-content-md-center">
                {
                    savedCards.map(sc => 
                        <ActivityCardSimple card={JSON.stringify(sc.card)} saved={JSON.stringify(sc.saved)}></ActivityCardSimple>
                    )
                }
                </Row>
            </Container>
            <AppFooter></AppFooter>
        </>
    );
}