import React from 'react';
import useColors from '../../services/hooks/useColors';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import ActivityCardCarouselConnected from '../../components/card/ActivityCardCarouselConnected';
import ActivityNavBar from '../../components/navbar/ActivityNavBar.js';
import useWindowDimensions from '../../services/hooks/useWindowDimensions';
import AppFooter from '../../components/footer/AppFooter';
import CartoonButton from '../../components/button/CartoonButton';
import { StripeButtonAction } from '../../components/button/StripeButton';
import ActivityCardGridConnected from '../../components/card/ActivityCardGridConnected';
import { useSelector } from 'react-redux';
import { selectListEnabled } from './TaskActivitySlice';

import styles from './TaskActivity.module.css';

const backgroundColor = "purple";
const fontColor = "white"

function CarouselNavigationRow(props) {
    return (
        <>
        <Row className="pv4 tc">
            <Col></Col>
            <Col>
                <StripeButtonAction title="prev" linkAction={props.prevSlide}></StripeButtonAction>
            </Col>
            <Col>
                <CartoonButton title="Random" linkAction={props.randomSlide} fontClass={styles.taskactivityappfontblack} ></CartoonButton>
            </Col>
            <Col>
                <StripeButtonAction title="next" linkAction={props.nextSlide}></StripeButtonAction>
            </Col>
            <Col></Col>
        </Row>
        </>
    )
}

function ActivityCardInfoRow(props) {
    return (
        <>
            <Row className="tc pt4">
                <Col>{props.card === undefined ? "Loading..." : props.card.title}</Col>
            </Row>
        </>
    );
}

const widthBreak = 1200;

export default function TaskActivity(props) {

    useColors(backgroundColor, fontColor);
    const listEnabled = useSelector(selectListEnabled);
    const { width } = useWindowDimensions();
    
    let showCarousel = true;
    if (width < widthBreak || listEnabled) {
        showCarousel = false;
    }

    return (
        <>
            <ActivityNavBar track={props.track} fontClass={styles.taskactivityappfontwhite}></ActivityNavBar>
            <Container>
                {
                    showCarousel ?
                        <CarouselNavigationRow
                            nextSlide={props.nextSlide}
                            prevSlide={props.prevSlide}
                            randomSlide={props.randomSlide}
                            >
                        </CarouselNavigationRow> :
                        null
                }
                {
                    showCarousel ?
                            <ActivityCardCarouselConnected track={props.track}></ActivityCardCarouselConnected> :
                            <ActivityCardGridConnected track={props.track}></ActivityCardGridConnected>
                } 
                
            </Container>
            <AppFooter></AppFooter>
        </>
    );
}

//{showCarousel ? <ActivityCardInfoRow card={props.cards[props.goToSlide]}></ActivityCardInfoRow> : null}