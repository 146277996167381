import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { appName } from '../../config/strings.js';
import WonkyTitle from '../labels/WonkyTitle.js';
import StripeButton from '../button/StripeButton.js';

import styles from './AppNavBar.module.css';

export default function AppNavBar(props) {
    return (
    <header>
        <Navbar sticky="top" className="justify-content-between" expand="lg">

            <Navbar.Brand href="/" className={styles.navbartypography}>
                <WonkyTitle title={appName}></WonkyTitle>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                
                <Nav className="mr-auto">
                </Nav>

                <Nav className="mr-sm-2">
                    <StripeButton to= "/about" title="About" fontClass={styles.navbartypography + " " + props.fontClass}></StripeButton>
                    <StripeButton to= "https://makeall.dev" title="Blog" fontClass={styles.navbartypography + " " + props.fontClass}></StripeButton>
                </Nav>
                
            </Navbar.Collapse>
        </Navbar>
      </header>
    )
}

/*<StripeButton to= "/comingsoon" title="Leaderboard" fontClass={styles.navbartypography + " " + props.fontClass}></StripeButton>*/