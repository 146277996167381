import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from 'uuid';
import { ActivityCard } from '../../components/card/ActivityCard';
import debugLog from "../../services/debug/log";

export default class ActivityCardCarousel extends Component {

    componentDidMount() {
        this.props.changeTrack();
    }

    render() {
        let slides = [];
        for (let i = 0; i < this.props.cards.length; i++) {
            let index = this.props.cards[i].index;
            let saved = localStorage.getItem(index) ?? JSON.stringify(JSON.stringify({}));
            let key = uuidv4();
            slides.push({
                key: key,
                content: 
                
                <ActivityCard key={key} card={JSON.stringify(this.props.cards[i])} saved={JSON.stringify(saved)}>
                </ActivityCard>
            });
        }
        slides = slides.map((slide, index) => {
            return { ...slide, onClick: () => {
                debugLog.info("Loaded slide index %d", index);
                this.props.pickSlide(index)
            }};
        });
        return (
            <>
            <div style={{ width: "60vw", height: "60vh", margin: "0", padding: "0" }}>
                <Carousel
                    slides={slides}
                    goToSlide={this.props.goToSlide}
                    offsetRadius={this.props.offsetRadius}
                    showNavigation={this.props.showNavigation}
                    animationConfig={this.props.config}
                    goToSlideDelay={this.props.goToSlideDelay}
                />
            </div>
            </>
        )
    }
}
