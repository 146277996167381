import { createSlice } from '@reduxjs/toolkit';
import {PLAN, BUILD, LAUNCH} from '../../config/tracks';
import { config } from "react-spring";
import cardInfo from './CardInfo';
import { allCards } from './CardInfo';

const initialState = {
    track: null,
    cards: [],
    listEnabled: false,
    totalSlides: 0,
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    config: config.gentle,
    goToSlideDelay: 200,
    saved: {},
}

allCards.map(card => initialState.saved[card.index] = localStorage.getItem(card.index) ?? {});

export const taskActivitySlice = createSlice({
    name: "taskactivity",
    initialState,
    reducers: {
        changeTrack: (state, action) => {
            let curTrack = null;
            switch (action.payload) {
                case PLAN:
                case BUILD:
                case LAUNCH:
                    state.track = action.payload;
                    curTrack = state.track;
                    break;
                default:
                    break;
            }
            state.cards = [];
            if (curTrack !== null) {
                for (let i = 0; i < cardInfo[curTrack].length; i++) {
                    state.cards.push(cardInfo[curTrack][i]);
                }
            }
            state.totalSlides = state.cards.length;
            state.goToSlide = 0;
        },
        randomSlide: (state, action) => {
            state.goToSlide = Math.floor(Math.random() * state.totalSlides);
        },
        nextSlide: (state, action) => {
            state.goToSlide = state.goToSlide === state.totalSlides-1 ? 0: state.goToSlide+1
        },
        prevSlide: (state, action) => {
            state.goToSlide = state.goToSlide === 0 ? state.goToSlideDelay-1 : state.goToSlide-1
        }, 
        pickSlide: (state, action) => {
            state.goToSlide = action.payload;
        },
        toggleList: (state, action) => {
            if (state.listEnabled) {
                state.listEnabled = false;
            } else {
                state.listEnabled = true;
            }
        },
        saveCard: (state, action) => {
            localStorage.setItem(action.payload.card.index, JSON.stringify(action.payload.response));
            state.saved[action.payload.card.index] = action.payload.response;
        },
        purgeSaved: (state, action) => {
            allCards.map(card => state.saved[card.index] = {});
            allCards.map(card => localStorage.removeItem(card["index"]));
        },
    }
});


export const selectListEnabled = (state) => state.taskactivity.listEnabled;
export const selectSaved = (state) => state.taskactivity.saved;
export const selectCards = (state) => state.taskactivity.cards;
export const { changeTrack, randomSlide, nextSlide, prevSlide, pickSlide, toggleList, saveCard, purgeSaved } = taskActivitySlice.actions;
export default taskActivitySlice.reducer;