import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './AppFooter.module.css';

export default function AppFooter() {
    return (
        <footer className={styles.appfooter}>
            <Container fluid>
                <Row>
                    <Col className="tr pt5 pb3 mt5 mh3 ph3">
                    © 2021 makeall.dev, All Rights Reserved.
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}