import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { LINK, OPTION, CHECKBOX, TEXTBOX, LINKS, CHOICES } from '../../config/tracks';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import styles from './card.module.css';
import { useDispatch } from 'react-redux';
import { saveCard } from '../../features/taskactivity/TaskActivitySlice';
import Dropdown from 'react-bootstrap/Dropdown';

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function ActivityLink(props) {
    const dispatch = useDispatch();
    let card = JSON.parse(props.card);
    let visited = JSON.parse(props.saved).visited ?? false;
    return (
        <>
        <Button variant="outline-info" href={props.href} target="_blank" onClick={() => dispatch(saveCard( { "card": card, "response": {"visited": true} } ))}>External Link</Button>
        </>
    );
}

function ActvityOption(props) {

    const dispatch = useDispatch();
    let card = JSON.parse(props.card);
    let choices = props.choices.split(",");
    let selected = JSON.parse(JSON.parse(props.saved)).selected ?? "";

    const [curSelected, setCurSelected] = useState(selected.length === 0 ? 'select' : selected)

    return (
        <>
        <Dropdown>
            <Dropdown.Toggle variant="dark" small="sm">
                {curSelected}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    choices.map((item) => 
                        <Dropdown.Item
                            key={item.replaceAll(" ", "")}
                            onClick={() => dispatch(saveCard( { "card": card, "response": { "selected": item } } ))}
                            >
                            {item}
                        </Dropdown.Item>
                        )
                }
                
            </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

function ActivityCheckbox(props) {
    
    const dispatch = useDispatch();
    let card = JSON.parse(props.card);
    let choices = props.choices.split(",");
    let selected = JSON.parse(JSON.parse(props.saved)).selected ?? [];

    return (
        <>
        {
            choices.map(
                (item) => 
                <Form.Check
                    key={item.replaceAll(" ", "")}
                    label={item}
                    checked={selected.includes(item)}
                    onChange={
                        () => {
                            if (selected.includes(item)) {
                                selected.splice(selected.indexOf(item), 1);
                                dispatch(saveCard({ "card": card, "response": { "selected": selected }} ));
                            } else {
                                dispatch(saveCard({ "card": card, "response": { "selected": selected.concat([item])}} ));
                            }
                        }
                    }
                    />
                )
        }
        </>
    );
}

function ActivityTextBox(props) {

    const dispatch = useDispatch();
    let card = JSON.parse(props.card);
    let savedValue = JSON.parse(JSON.parse(props.saved)).input ?? "";
    
    const [textBoxValue, setTextBoxValue] = useState(savedValue);

    return (
        <>
        <Form.Group className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
                type="text"
                placeholder={savedValue}
                value={textBoxValue}
                onChange={event => setTextBoxValue(event.target.value)}  />
            <Form.Text className="text-muted">
            
            </Form.Text>
            <Button variant="dark" size="sm" onClick={() => dispatch(saveCard({ "card": card, "response": {"input": textBoxValue }}))}>
                Submit
            </Button>
        </Form.Group>
        </>
    );    
}

function ActivityNothing(props) {
    return (
        <>
        <p></p>
        </>
    );
}

function ActivityAction(props) {
    let card = JSON.parse(props.card);

    switch(card.kind) {
        case LINK:
            return (
                <ActivityLink card={props.card} href={card.meta[LINKS][getRandomInt(card.meta[LINKS].length)]} saved={props.saved}></ActivityLink>
            );
        case OPTION:
            
            return (
                <ActvityOption card={props.card} choices={card.meta[CHOICES].join()} saved={props.saved}></ActvityOption>
            );
        case CHECKBOX:
            return (
                <ActivityCheckbox card={props.card} choices={card.meta[CHOICES].join()} saved={props.saved}></ActivityCheckbox>
            );
        case TEXTBOX:
            return (
                <ActivityTextBox card={props.card} saved={props.saved}></ActivityTextBox>
            );
        default:
            return (
                <ActivityNothing card={props.card} saved={props.saved}></ActivityNothing>
            );
    }
}

export function ActivityCardSimple(props) {
    let card = JSON.parse(props.card);
    return (
        <>
            <Card className="text-center" style={{ padding: '10px', margin: '10px', "backgroundColor": "#ffc8a8" }}>
                <Card.Header className={styles.cardtypographytitle}>{card.title}</Card.Header>
                <Card.Body className={styles.cardtypographybody}>
                    <Card.Text>
                        <Alert variant="warning">
                            {card.description}
                        </Alert>
                    </Card.Text>
                    <hr></hr>
                    Task
                    <hr></hr>
                    <ActivityAction card={props.card} saved={props.saved}></ActivityAction>
                </Card.Body>
            </Card>
        </>
    );
}

export function ActivityCard(props) {
    let card = JSON.parse(props.card);

    return (
        <>
        <div style={{ padding: '10px', margin: 'auto', width: '18vw', height: '50vh', "backgroundColor": "#6e469c", textAlign: 'center' }}>
            <Card className="text-center" style={{  width: '17vw', height: '48vh',"backgroundColor": "#ffc8a8" }}>
                <Card.Header className={styles.cardtypographytitle}>{card.title}</Card.Header>
                <Card.Body className={styles.cardtypographybody}>
                    <Card.Text>
                        <Alert variant="warning">
                            {card.description}
                        </Alert>
                    </Card.Text>
                    <hr></hr>
                    Task
                    <hr></hr>
                    <ActivityAction card={props.card} saved={props.saved}></ActivityAction>
                </Card.Body>
            </Card>
        </div>
        
        </>
    );
}
