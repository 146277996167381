import React from 'react';
import styles from './labels.module.css';

export default function WonkyTitle(props) {
    return (
        <>
            <span className={styles.shakyshaky}>
                {props.title}
            </span>
        </>
    );
}